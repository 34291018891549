<template>
    <div>
        <div class="imagediv">
            <el-image style="width:100%" :src="require('@/assets/iphone/xieyitop.png')"></el-image>
            <div class="title">用户协议</div>
        </div>
        <div class="content" v-html="info.xieyi"></div>
    </div>
</template>
<script>
import { getXieyi} from "@/api/api"; 
export default {
    data(){
        return{
            info:{}
        }
    },
    created(){
        this.getList()
    },
    methods:{
         async getList(){
            const {data:res} = await getXieyi()
            this.info = res
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
}
</script>
<style scoped>
    .imagediv{
        position: relative;
    }
    .title{
        position: absolute;
        top: 30%;
        left:4%;
        font-family: SourceHanSansCN-Heavy;
        font-size: 28px;
        color: #fff;
        font-weight: bold;
    }
    .content{
        padding-left: 15px;
        padding-right: 15px;
    }
    .content >>>p{
        padding: 0px;
        margin: 0 auto;
    }
    .content >>>span{
        line-height: 30px;
    }
</style>